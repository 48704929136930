<template>
  <div
    class="row justify-center q-py-lg"
    :class="[
      $q.screen.gt.sm ? 'q-px-xl' : 'q-px-md',
    ]"
  >
    <slot />
  </div>
</template>
